﻿const rootEl = document.querySelector(".js-shows-portal-app");
if (rootEl) {

    (function () {
        const API_ENDPOINTS = {
            PublishShow: '/api/shows/publishshow/',
            GetListItems: '/api/shows/getlistitems/',
            AddSessions: '/api/shows/addsessions/',
            GetShowSessions: '/api/shows/getsessions/',
            UpdateTickInfo: '/api/shows/updatetickinfo/',
            AddReviewsAndAwards: '/api/shows/addreviewandawards/',
            AddExternalLinks: '/api/shows/addexternallinks/',
            AddRegistration: '/api/shows/addregistration/',
            PostFiles: '/api/shows/postfiles/',
            PostFile: '/api/shows/postfile/',
            AddImages: '/api/shows/addimages/',
            AddCroppedImage: '/api/shows/addcroppedimage/',
            DeleteNode: '/api/shows/deletenode/'
        };

        const showsPortalApp = new Vue({
            el: '.js-shows-portal-app',
            name: 'Shows Portal App',
            data() {
                return {
                    availableSections: [
                        'event-details',
                        'when-and-where',
                        'ticketing-and-accessibility',
                        'reviews-and-awards',
                        'social-details',
                        'event-images',
                        'show-registration'
                    ],
                    currentSection: 'event-details',

                    showEssentialsErrors: {},
                    showTicketingErrors: {},
                    imageErrors: { ErrorMessage: null },

                    completeListItems: {},

                    rteFullDesc: null,
                    rteVenueAcc: null,
                    rteTicketingInfo: null,

                    currentShowId: null,
                    showSessionEditor: null,
                    showSessionEditorStatus: 'init',
                    showSessionItems: [],
                    showReviews: [{
                        NameOfReviewer: null,
                        StarRating: null,
                        Excerpt: null,
                    }],
                    showAwards: [{
                        NameOfAward: null,
                    }],
                    showRegistration: '',
                    showExternalLinks: [{
                        URL: null,
                    }],
                    showReviewEditorStatus: 'init',
                    showAwardEditorStatus: 'init',
                    showExternalLinkEditorStatus: 'init',
                    showRegistrationEditorStatus: 'init',
                    showImageEditorStatus: 'init',
                    showcroppedImageUploadStatus: 'init',
                    showcroppedImageEditorStatus: 'init',
                    showImages: [],
                    showImageCropped: '',
                    eventImagePreviewObjectUrl: null
                }
            },
            async mounted() {
                // fetch the complete lists
                const completeListItemsRequest = await fetch(API_ENDPOINTS.GetListItems);

                if (!completeListItemsRequest.ok) {
                    console.error("Couldn't get complete lists");
                    return;
                }

                this.completeListItems = await completeListItemsRequest.json();

                const rteConfigs = {
                    theme: 'snow',
                    modules: {
                        toolbar: ['bold', 'code', 'italic', 'strike', 'underline', 'link'],
                    }
                };

                // Initialise Quill RTE - Full Description
                this.rteFullDesc = new Quill('.js-show-full-description', rteConfigs);

                // Initialise Quill RTE - Venue Accessibility Information
                this.rteVenueAcc = new Quill('.js-show-venue-accessibility-information', rteConfigs);

                // Initialise Quill RTE - Ticketing Info
                this.rteTicketingInfo = new Quill('.js-show-ticketing-info', rteConfigs);
                // Set ShowId
                if (window.PrideShow.ShowId) {
                    this.currentShowId = window.PrideShow.ShowId;
                }

                // Fetch all show sessions if ShowId has been set
                if (this.currentShowId) {
                    await this.getAllShowSessions(this.currentShowId);
                }

                //Check image errors and show the image section
                if (window.ShowImageErrors?.ErrorMessage) {
                    this.imageErrors.ErrorMessage = window.ShowImageErrors.ErrorMessage;
                    this.currentSection = 'event-images';
                }

                // Populate reviews and awards ....
                let jsonBearer = document.querySelector('.js-show-reviews-and-awards');
                const showReviewsRecords = JSON.parse(jsonBearer.dataset.showReviews);
                if (showReviewsRecords.length) {
                    this.showReviews = showReviewsRecords;
                }
                const showAwardsRecords = JSON.parse(jsonBearer.dataset.showAwards);
                if (showAwardsRecords.length) {
                    this.showAwards = showAwardsRecords;
                }

                //populate images

                jsonBearer = document.querySelector('.js-show-images');
                const uploadedImages = JSON.parse(jsonBearer.dataset.showImages);
                if (uploadedImages.length) {
                    this.showImages = uploadedImages;
                }
                jsonBearer = document.querySelector('.js-show-image-cropped');
                this.showImageCropped = jsonBearer.dataset.croppedImages;


                // Populate external links ....
                jsonBearer = document.querySelector('.js-show-external-links');
                const showExternalLinksRecords = JSON.parse(jsonBearer.dataset.showExternalLinks);
                if (showExternalLinksRecords.length) {
                    this.showExternalLinks = showExternalLinksRecords;
                }

                // Populate show registration ....
                let valueBearer = document.querySelector('#show-section-show-registration');
                this.showRegistration = valueBearer.dataset.showRegistration;
            },
            methods: {

                setTime() {
                    var hourEl = document.getElementById("hour");
                    var minuteEl = document.getElementById("minute");
                    var amEl = document.getElementById("am");
                    var hour = "00";

                    if (hourEl.value.length != 0) {
                        if (!amEl.checked) {
                            if (parseInt(hourEl.value) == 12) {
                                hour = hourEl.value
                            } else {
                                hour = `${parseInt(hourEl.value) + 12}`;
                            }
                        } else {
                            if (parseInt(hourEl.value) != 12) {
                                hour = hourEl.value;
                            }
                        }
                    }

                    var minute = minuteEl.value.length == 0 ? "00" :
                        parseInt(minuteEl.value) < 10 ? "0" + minuteEl.value : minuteEl.value;

                    if (minute === "000") {
                        minute = "00";
                    }
                    var strFullTime = hour + ":" + minute;
                    this.showSessionEditor.SessionTime = strFullTime;
                },

                async saveAddShowReview() {
                    this.showReviewEditorStatus = 'saving';
                    const response = await fetch(API_ENDPOINTS.AddReviewsAndAwards, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            ShowId: this.currentShowId,
                            Reviews: this.showReviews,
                            Awards: this.showAwards
                        })
                    });

                    if (!response) {
                        console.error('Error while saving reviews');
                    }

                    const responseText = await response.text();

                    if (responseText === 'true') {
                        this.showReviewEditorStatus = 'saved';

                        let lastReview = this.showReviews[this.showReviews.length - 1];
                        let hasContent = false;
                        for (const item in lastReview) {
                            if (lastReview[item] !== null || lastReview[item] !== '') {
                                hasContent = true;
                                break;
                            }
                        }

                        if (!hasContent) return;

                        this.showReviews.push({
                            NameOfReviewer: null,
                            StarRating: null,
                            Excerpt: null,
                        })

                        const that = this;
                        setTimeout(() => {
                            that.showReviewEditorStatus = null;
                        }, 1000);
                    }
                },
                async saveAddShowAward() {
                    this.showAwardEditorStatus = 'saving';
                    const response = await fetch(API_ENDPOINTS.AddReviewsAndAwards, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            ShowId: this.currentShowId,
                            Reviews: this.showReviews,
                            Awards: this.showAwards
                        })
                    });

                    if (!response) {
                        console.error('Error while saving awards');
                    }

                    const responseText = await response.text();

                    if (responseText === 'true') {
                        this.showAwardEditorStatus = 'saved';

                        let lastItem = this.showAwards[this.showAwards.length - 1];
                        let hasContent = false;
                        for (const item in lastItem) {
                            if (lastItem[item] !== null || lastItem[item] !== '') {
                                hasContent = true;
                                break;
                            }
                        }

                        if (!hasContent) return;

                        this.showAwards.push({
                            NameOfAward: null,
                        })

                        const that = this;
                        setTimeout(() => {
                            that.showAwardEditorStatus = null;
                        }, 1000);
                    }
                },
                async saveAddShowRegistration(e) {
                    const form = e.target;
                    const formData = new FormData(form);
                    const jsonFormData = Object.fromEntries(formData);

                    this.showRegistrationEditorStatus = 'saving';
                    const response = await fetch(API_ENDPOINTS.AddRegistration, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            ShowId: this.currentShowId,
                            Registration: jsonFormData.ShowRegistration
                        })
                    });

                    if (!response) {
                        console.error('Error while saving show registration');
                    }

                    const responseText = await response.text();

                    if (responseText === 'true') {
                        this.showRegistrationEditorStatus = 'saved';

                        const that = this;
                        setTimeout(() => {
                            that.showRegistrationEditorStatus = null;
                        }, 1000);
                    }
                },
                async submitShowImages(e) {
                    const form = e.target;
                    const formData = new FormData(form); // keep it as formData for file submission

                    // For debugging only
                    const formDataJson = Object.fromEntries(formData);

                    //console.log(formDataJson);

                    // Validation
                    this.imageErrors = { ErrorMessage: null };

                    this.showImageEditorStatus = 'saving';
                    const imageUploadingResponse = await fetch(API_ENDPOINTS.PostFiles, {
                        method: 'post',
                        body: formData
                    });

                    if (!imageUploadingResponse) {
                        console.error('Error while saving show registration');
                    }

                    const imageUploadingResponseJson = await imageUploadingResponse.json();

                    if (imageUploadingResponseJson.length) {
                        this.showImages = imageUploadingResponseJson;
                    }

                    const associateImagesResponse = await fetch(API_ENDPOINTS.AddImages, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ShowId: this.currentShowId,
                            Images: this.showImages
                        })
                    })

                    const responseText = await associateImagesResponse.text();

                    if (responseText === 'true') {
                        this.showImageEditorStatus = 'saved';
                        form.reset();

                        const that = this;
                        setTimeout(() => {
                            that.showImageEditorStatus = null;
                        }, 1000);
                    }
                },
                async uploadCroppedImage(e) {
                    /*this.showImageCropped = '{"src":"/media/z4znd4bl/1600w_800h_phm_01.jpeg","focalPoint":{},"crops":[]}';*/
                    const form = e.target;
                    const formData = new FormData(form); // keep it as formData for file submission

                    // For debugging only
                    const formDataJson = Object.fromEntries(formData);

                    //console.log(formDataJson);

                    // Validation
                    this.imageErrors = { ErrorMessage: null };

                    this.showcroppedImageUploadStatus = 'saving';
                    const imageUploadingResponse = await fetch(API_ENDPOINTS.PostFile, {
                        method: 'post',
                        body: formData
                    });

                    if (!imageUploadingResponse) {
                        console.error('Error while saving show registration');
                    }

                    const imageUploadResponseText = await imageUploadingResponse.json();

                    if (imageUploadResponseText != null && imageUploadResponseText.src != null) {
                        this.showImageCropped = {
                            "src": imageUploadResponseText.src,
                            "focalPoint": {},
                            "crops": []
                        };

                        this.showcroppedImageUploadStatus = 'saved';
                        form.reset();

                        const that = this;
                        setTimeout(() => {
                            that.showcroppedImageUploadStatus = null;
                        }, 1000);
                    }
                },
                async saveImageCropping(e) {
                    var croppingsJson = JSON.stringify(this.showImageCropped);
                    const associateImagesResponse = await fetch(API_ENDPOINTS.AddCroppedImage, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ShowId: this.currentShowId,
                            CroppedImage: croppingsJson
                        })
                    });

                    const responseText = await associateImagesResponse.text();
                    if (responseText === 'true') {
                        this.showcroppedImageEditorStatus = 'saved';

                        const that = this;
                        setTimeout(() => {
                            that.showcroppedImageEditorStatus = null;
                        }, 1000);
                    }
                },
                async saveAddShowExternalLinks() {
                    this.showExternalLinkEditorStatus = 'saving';

                    const response = await fetch(API_ENDPOINTS.AddExternalLinks, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            ShowId: this.currentShowId,
                            ExternalUrl: this.showExternalLinks
                        })
                    });

                    if (!response) {
                        console.error('Error while saving awards');
                    }

                    const responseText = await response.text();

                    if (responseText === 'true') {
                        this.showExternalLinkEditorStatus = 'saved';

                        let lastItem = this.showExternalLinks[this.showExternalLinks.length - 1];
                        let hasContent = false;
                        for (const item in lastItem) {
                            if (lastItem[item] !== null && lastItem[item] !== '') {
                                hasContent = true;
                                break;
                            }
                        }

                        if (!hasContent) return;

                        this.showExternalLinks.push({
                            URL: null,
                        })

                        const that = this;
                        setTimeout(() => {
                            that.showExternalLinkEditorStatus = null;
                        }, 1000);
                    }
                },
                toggleAccordionItem(sectionAlias) {
                    if (this.currentShowId === null) return;

                    this.currentSection = this.currentSection === sectionAlias ? '' : sectionAlias;

                    // Scroll to the next section
                    setTimeout(() => {
                        location.hash = '#show-section-' + sectionAlias;
                    }, 100);
                },
                async submitShowEssentials(e) {
                    const form = e.target;
                    const formData = new FormData(form);
                    const jsonFormData = Object.fromEntries(formData);

                    // Process Genres
                    jsonFormData['Genres'] = formData.getAll('Genres[]');
                    delete (jsonFormData['Genres[]']);

                    // Process Sponsors
                    jsonFormData['Sponsors'] = formData.getAll('Sponsors[]');
                    delete (jsonFormData['Sponsors[]']);

                    // Append FullDescription with Quill Editor data
                    jsonFormData.FullDescription = this.rteFullDesc.root.innerHTML;

                    if (this.currentShowId !== null) {
                        jsonFormData['ShowId'] = this.currentShowId;
                    }

                    // Validation
                    this.showEssentialsErrors = {};
                    const requiredFields = Array.from(form.querySelectorAll('[required="required"]')).map(x => x.name);

                    requiredFields.forEach(field => {
                        if (jsonFormData[field] === null || jsonFormData[field] === '') {
                            this.showEssentialsErrors['field'] = true;
                        }
                    })

                    if (jsonFormData.FullDescription === '<p><br></p>') {
                        this.showEssentialsErrors.FullDescription = true;
                    }

                    if (!jsonFormData.Genres.length) {
                        this.showEssentialsErrors.Genres = true;
                    }

                    //if (!jsonFormData.Sponsors.length) {
                    //    this.showEssentialsErrors.Sponsors = true;
                    //}


                    if (Object.keys(this.showEssentialsErrors).length) {
                        console.error("Form errors");
                        return;
                    }
                    // Stringify for request
                    const jsonString = JSON.stringify(jsonFormData);

                    const response = await fetch(API_ENDPOINTS.PublishShow, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: jsonString
                    });

                    if (response.ok == false) {
                        console.error("POST had errors");
                    }
                    var responseClone = response.clone()
                    const responseJson = await responseClone.json();
                    if (responseJson['ShowId']) {
                        this.currentShowId = responseJson['ShowId'];
                        document.cookie = `ShowIdCookieKey=${this.currentShowId};`;
                        this.toggleAccordionItem('when-and-where');

                        /* Update save and preview href attribute to newly created ShowId */
                        const baseUrl = document.getElementById('save_preview').getAttribute('href').split("?")[0];
                        document.getElementById('save_preview').setAttribute('href', `${baseUrl}?eventId=${this.currentShowId}`);
                        /* end of update */
                    } else {
                        console.error("Json parse error.", response, responseJson);
                    }
                },
                async submitReviewsAndAwards(e) {
                    this.toggleAccordionItem('social-details');

                },
                async submitShowTicketing(e) {
                    const form = e.target;
                    const formData = new FormData(form);
                    const jsonFormData = Object.fromEntries(formData);

                    jsonFormData['ContentRatings'] = formData.getAll('ContentRatings[]');
                    delete (jsonFormData['ContentRatings[]']);

                    jsonFormData['ContentWarnings'] = formData.getAll('ContentWarnings[]');
                    delete (jsonFormData['ContentWarnings[]']);

                    jsonFormData.TicketingInfo = this.rteTicketingInfo.root.innerHTML;
                    jsonFormData.AccessibilityInfo = this.rteVenueAcc.root.innerHTML;

                    if (this.currentShowId !== null) {
                        jsonFormData.ShowId = this.currentShowId;
                    }

                    // Validation
                    this.showTicketingErrors = {};
                    const requiredFields = Array.from(form.querySelectorAll('[required="required"]')).map(x => x.name);

                    requiredFields.forEach(field => {

                        if (jsonFormData[field] === null || jsonFormData[field] === '') {
                            this.showTicketingErrors['field'] = true;
                        }
                    })

                    /*if (!jsonFormData.ContentRatings.length) {
                        this.showTicketingErrors.ContentRatings = true;
                    }
                    
                    if (!jsonFormData.ContentWarnings.length) {
    
                        if (jsonFormData.ToBeAddedContentWarning === "") {
                            this.showTicketingErrors.ContentWarnings = true;
                        }
                    }*/

                    if (Object.keys(this.showTicketingErrors).length) {
                        console.error("Form errors");
                        return;
                    }


                    // Stringify for request
                    const jsonString = JSON.stringify(jsonFormData);

                    const response = await fetch(API_ENDPOINTS.UpdateTickInfo, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: jsonString
                    });

                    if (!response.ok) {
                        console.error("POST had errors");
                    }

                    const responseText = await response.text();

                    if (responseText === 'true') {
                        this.toggleAccordionItem('reviews-and-awards');
                    } else {
                        console.error("Error saving ticketing info.", response);
                    }
                },
                openEditShowSession() {
                    this.showSessionEditor = {
                        IsOnlineOnly: null,
                        Venue: null,
                        NewVenue: null,
                        SessionDate: null,
                        SessionTime: null,
                        Duration: null,
                        HasIntermission: null,
                        ShowSessionId: null
                    };

                    const hourInputEl = document.querySelector('.js-session-time__hour');
                    if (hourInputEl) {
                        hourInputEl.value = '';
                    }
                    const minuteInputEl = document.querySelector('.js-session-time__minute');
                    if (minuteInputEl) {
                        minuteInputEl.value = '';
                    }
                },
                async addUpdateShowSession() {
                    // Some date time conversions
                    const theDateTime = `${this.showSessionEditor.SessionDate} ${this.showSessionEditor.SessionTime}`;
                    console.log(theDateTime);

                    // Validate required fields
                    const editorFields = Array.from(this.showSessionEditor);
                    let hasError = false;
                    editorFields.forEach((value, index) => {
                        if (!value || value === '') {
                            this.showSessionEditor.errors[index] = true;
                            hasError = true;
                        }
                    })

                    if (hasError) return;

                    if (theDateTime.includes("null")) return;

                    this.showSessionEditorStatus = 'saving';
                    const response = await fetch(API_ENDPOINTS.AddSessions, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ShowId: this.currentShowId,
                            Sessions: [{
                                IsOnlineOnly: this.showSessionEditor.IsOnlineOnly,
                                Venue: this.showSessionEditor.Venue,
                                NewVenue: this.showSessionEditor.NewVenue,
                                SessionDate: theDateTime,
                                SessionTime: theDateTime,
                                Duration: this.showSessionEditor.Duration,
                                HasIntermission: this.showSessionEditor.HasIntermission,
                                ShowSessionId: this.showSessionEditor.ShowSessionId,
                            }]
                        })
                    });

                    if (!response.ok) {
                        console.error('Error while submitting show session');
                        return;
                    }

                    const responseJson = await response.json();

                    if (responseJson[0]['ShowSessionId']) {
                        this.showSessionEditor.ShowSessionId = responseJson[0]['ShowSessionId'];
                        this.showSessionEditorStatus = 'saved';
                        if (this.currentShowId) {
                            await this.getAllShowSessions(this.currentShowId);
                        }
                        const that = this;
                        setTimeout(() => {
                            that.showSessionEditorStatus = 'init';
                        }, 1000);
                    } else {
                        console.error('Error parsing session response.');
                    }
                },
                async getAllShowSessions(showId) {
                    const response = await fetch(API_ENDPOINTS.GetShowSessions + showId);

                    if (!response.ok) {
                        console.error('Error while getting all show sessions');
                        return;
                    }

                    this.showSessionItems = await response.json();
                },
                editShowSession(item) {
                    this.showSessionEditor = {
                        ...item
                    };
                    this.showSessionEditor.SessionDate = this.convertToISODate(this.showSessionEditor.SessionDate);
                    this.showSessionEditor.SessionTime = this.convertToISOTime(this.showSessionEditor.SessionTime);

                    setTimeout(() => {
                        var x = document.getElementById("SessionTime").value;
                        var hour = parseInt(x.split(":")[0]) > 12 ? x.split(":")[0] - 12 : parseInt(x.split(":")[0]);
                        document.getElementById("hour").value = hour;
                        document.getElementById("minute").value = x.split(":")[1];
                        document.getElementById("am").checked = parseInt(x.split(":")[0]) < 12 ? true : false;
                        document.getElementById("pm").checked = parseInt(x.split(":")[0]) < 12 ? false : true;
                    }, 50)
                },
                duplicateShowSession(item) {
                    this.showSessionEditor = {
                        ...item
                    };
                    this.showSessionEditor.SessionDate = this.convertToISODate(this.showSessionEditor.SessionDate);
                    this.showSessionEditor.SessionTime = this.convertToISOTime(this.showSessionEditor.SessionTime);
                    delete (this.showSessionEditor.ShowSessionId);

                    setTimeout(() => {
                        var x = document.getElementById("SessionTime").value;
                        var hour = parseInt(x.split(":")[0]) > 12 ? x.split(":")[0] - 12 : parseInt(x.split(":")[0]);
                        document.getElementById("hour").value = hour;
                        document.getElementById("minute").value = x.split(":")[1];
                        document.getElementById("am").checked = parseInt(x.split(":")[0]) < 12 ? true : false;
                        document.getElementById("pm").checked = parseInt(x.split(":")[0]) < 12 ? false : true;
                    }, 50)
                },
                async deleteShowSession(item) {
                    this.showSessionItems = [...this.showSessionItems].filter(x => x.ShowSessionId !== item.ShowSessionId);
                    const deleteNodeResponse = await fetch(`${API_ENDPOINTS.DeleteNode}/${item.ShowSessionId}`, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    console.log('***', deleteNodeResponse);
                },
                convertToLongDate(dateString) {
                    const date = new Date(dateString);
                    const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
                    return utcDate.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' });
                },
                convertToLongTime(dateString) {

                    const date = new Date(dateString);

                    // Extract hours, minutes, and seconds
                    let hours = date.getUTCHours();
                    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
                    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

                    // Determine AM or PM
                    const amOrPm = hours < 12 ? 'AM' : 'PM';

                    // Convert 24-hour format to 12-hour format
                    if (hours > 12) {
                        hours -= 12;
                    } else if (hours === 0) {
                        hours = 12;
                    }

                    return `${hours}:${minutes}:${seconds} ${amOrPm}`;
                },
                convertToISODate(dateString) {
                    return new Date(dateString).toLocaleDateString('en-CA', { year: 'numeric', month: 'numeric', day: 'numeric' });
                },
                convertToISOTime(dateString) {
                    return new Date(dateString).toLocaleTimeString('en-CA', { hour: '2-digit', minute: '2-digit', hour12: false });
                },
                getVenueName(venueId) {
                    let result;
                    this.completeListItems.VenuesList.forEach(item => {
                        if (parseInt(item.Value, 10) === parseInt(venueId, 10)) {
                            result = item.Text;
                        }
                    })
                    return result;
                },
                async deleteShowImage(item, event) {
                    event.target.disabled = true;
                    const newImages = this.showImages.filter(itm => {
                        return itm !== item;
                    })

                    const associateImagesResponse = await fetch(API_ENDPOINTS.AddImages, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ShowId: this.currentShowId,
                            Images: newImages
                        })
                    })

                    const responseText = await associateImagesResponse.text();

                    if (responseText === 'true') {
                        event.target.disabled = false;

                        const that = this;
                        that.showImages = newImages;
                    } else {
                        event.target.disabled = false;
                        console.error('Error while un-associate image.');
                    }
                },
                onEventImageInputChange(e) {
                    const [file] = e.target.files
                    if (file) {
                        this.eventImagePreviewObjectUrl = URL.createObjectURL(file)
                    }
                },
            }
        })
    })();
}